import React from 'react'
import '../../App.css'
import Footer from '../Footer'

function Products() {
  return (
    <>
        <h5 className='products'>Products</h5>
        <Footer />
    </>
  )
}

export default Products