import React from 'react'
import Footer from '../Footer'
import AboutHero from '../AboutHero'

function About() {
  return (
    <>
        <AboutHero />
        <Footer />
    </>
  )
}

export default About