import React from 'react'
import './LandingImageBannerList.css'
import ImageBanner from './ImageBanner'
import imageSrc from '../images/vet_with_pets/cat_f.webp'
import imageSrc2 from '../images/vet_with_pets/dog_f3.webp'
import imageSrc3 from '../images/vet_with_pets/dog_f4.webp'

function LandingImageBannerList() {
  return (
    <>
      <div className="image-banner-list-container">
        <ImageBanner 
          imageSrc={imageSrc} 
          title='Ask for help, 24/7' 
          subtitle="Members get 24/7 access to our AI-driven platform, offering expert assistance in numerous fields such as medical, legal, tech support, automotive, veterinary, home repairs, and more. Experience seamless support, anytime, anywhere." />
        <ImageBanner 
          style='image_banner_direction_reverse'
          imageSrc={imageSrc2} 
          title='An AI powerd expert will respond in minutes, Once you initiate contact' 
          subtitle="Once you make contact, we pair you with a specialist who is experienced in dealing with your specific situation. You can communicate via talk, text, or chat, depending on your preference. You can also share documents and photos for review." />
        <ImageBanner 
          imageSrc={imageSrc3} 
          title='Maximize Your Savings of Time and Money!' 
          subtitle="Eliminate the stress of scheduling, taking time off work, and pricey consultations. A Tiga membership offers substantial time and cost savings each month." />
      </div>

    </>
  )
}

export default LandingImageBannerList