import React from 'react'
import './Footer.css'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import logo from '../images/logo/256.png'

function Footer() {
  return (
    <>
        <div className="footer-container">
            {/* <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join the vet to recive our best vacation deals
                </p>
                <p className="footer-subscription-text">
                    You can unsubscribe at any time
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" name="email" placeholder="Your email" className="footer-input"/>
                        <Button buttonStyle='btn--outline'>Subscribe</Button>
                    </form>
                </div>
            </section> */}
            <hr />
            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Tiga</h2>
                        <Link to='/about-us'>About Us</Link>
                        <Link to='/terms'>Terms of Service</Link>
                        <Link to='/privacy'>Privacy Policy</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>Contact info</h2>
                        <Link to='/contacts'>Contact Us</Link>
                    </div>
                    {/* <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to='/about-us'>About Us</Link>
                        <Link to='/careers'>Careers</Link>
                        <Link to='/investors'>Investors</Link>
                        <Link to='/terms'>Terns of Service</Link>
                    </div> */}
                </div>
            </div>
            <section className="social-media">
                <div className="social-media-wrapper">
                    <div className="footer-logo">
                        <Link to='/' className="social-logo">
                            {/* TRVL <i className="fab fa-typo3" /> */}
                            TIGA <img src={logo} className='nav-icon' />
                        </Link>
                    </div>
                    <small className="website-rights">TIGA © 2024</small>
                    {/* <div className="social-icons">
                        <Link to='/' target="_blank" aria-label='Facebook' className="social-icon-link facebook">
                            <i className="fab fa-facebook-f" />
                        </Link>
                        <Link to='/' target="_blank" aria-label='Instagram' className="social-icon-link instagram">
                            <i className="fab fa-instagram" />
                        </Link>
                        <Link to='/' target="_blank" aria-label='Youtube' className="social-icon-link youtube">
                            <i className="fab fa-youtube" />
                        </Link>
                        <Link to='/' target="_blank" aria-label='Twitter' className="social-icon-link twitter">
                            <i className="fab fa-youtube" />
                        </Link>
                        <Link to='/' target="_blank" aria-label='LinkedIn' className="social-icon-link linkedin">
                            <i className="fab fa-linkedin" />
                        </Link>
                    </div> */}
                </div>
            </section>
        </div>
    </>
  )
}

export default Footer