import React, { Suspense, lazy } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Contacts from './components/pages/Contacts';
import Products from './components/pages/Products';
import About from './components/pages/About';
import ScrollToTop from './components/ScrollToTop'

const Privacy = lazy(() => import('./components/pages/Privacy'));
const Terms = lazy(() => import('./components/pages/Terms'));



function App() {
  return (
    <div className='app'>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path='/' exact Component={Home} />
            <Route path='/services' Component={Services} />
            <Route path='/products' exact Component={Products} />
            <Route path='/about-us' exact Component={About} />
            <Route path='/contacts' exact Component={Contacts} />
            <Route path='/terms' exact Component={Terms} />
            <Route path='/privacy' exact Component={Privacy} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
