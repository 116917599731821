import React from 'react'
import Footer from '../Footer'
import TitleSubtitle from '../TitleSubtitle'
import './Services.css'
import chatScreenshot from '../../images/services/chat_thread.png'
import chatNewScreenshot from '../../images/services/chat_thread_new.jpg'
import ImageView from '../ImageView'

function Services() {

  const chat_introduction = {'title': 'Experience top-notch 24/7 veterinary care from the comfort of your home.', 'subtitle': "We provide quick, accurate health solutions, and partnerships with diagnostic labs, all aimed at ensuring your pet's wellbeing at affordable rates."}
  return (
    <>
        <div className='service-container'>
          <div className="chat-container">
            <div className="service-introduction-container">
              <TitleSubtitle {...chat_introduction}/>
            </div>
            <div className="chat-thread-container">
              <ImageView src={chatNewScreenshot} imageViewStyle='imageview--half'/>
            </div>
          </div>
          <div className="service-list-container">
            <TitleSubtitle title="What’s included"/>
            <ul className='service-list'>
              <li>
                <TitleSubtitle title="Chat with a Veterinarian 1:1" subtitle="Connect with verified veterinarians in minutes, 24/7. Your membership can save you time & money every time you use it."/>
              </li>
              <li>
                <TitleSubtitle title="Proactive Pet Health Management Notifications" subtitle="The health management service is designed to keep pet parents one step ahead. It sends regular notifications to help manage your pet's health, preventing illnesses before they occur. Stay informed, stay prepared, and keep your pet healthy"/>
              </li>
              <li>
                <TitleSubtitle title="Trusted, Safe, and Data-Driven Pet Care" subtitle="We utilizes millions of vet records from around the world to accurately diagnose and provide valuable health suggestions for your pet. Trust us to provide informed, data-driven care for your furry friends"/>
              </li>
            </ul>
          </div>
        </div>

        <Footer></Footer>
    </>
  )
}

export default Services