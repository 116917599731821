import React from 'react'
import './TitleSubtitle.css'

function TitleSubtitle(props) {
    const { title, subtitle} = props
  return (
    <>
        <div className="title-subtitle-container">
            <h1>{title}</h1>
            <p>{subtitle}</p>
        </div>
    </>
  )
}

export default TitleSubtitle