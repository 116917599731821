import React from 'react'
import ImageView from './ImageView'
import TitleSubtitle from './TitleSubtitle'
import './ImageBanner.css'

const STYLES = ['image_banner_direction_normal', 'image_banner_direction_reverse']

function ImageBanner(props) {

    const {imageSrc, title, subtitle, style} = props

    const directionStyle = STYLES.includes(style) ? style : STYLES[0]
  return (
    <>
        <div className= {`image_banner_container ${directionStyle}`}>
            <ImageView src={imageSrc} imageViewStyle='imageview--half' />
            <TitleSubtitle {...props}/>
        </div>
    </>
  )
}

export default ImageBanner