import '../../App.css'
import { HeroSection } from "../HeroSection";
import Cards from '../Cards';
import Footer from '../Footer';
import LandingImageBannerList from '../LandingImageBannerList';

function Home() {
    return (
        <>
            <HeroSection />
            {/* <Cards /> */}
            <LandingImageBannerList />
            <Footer />
        </>
    )
}

export default Home;