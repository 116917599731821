import React from 'react'
import './AboutHero.css'
import TitleSubtitle from './TitleSubtitle'
import ImageView from './ImageView'
import aboutImageSrc from '../images/vet_with_pets/dog_f6.webp'

function AboutHero() {
  return (
    <>
        <div className="about-hero-container">
            <TitleSubtitle title='About us' subtitle='Tiga is an AI-Powered Online Animal Hospital, Our mission is to maintain the health and happiness of both pets and their parents, without putting a strain on your budget.' />
            <ImageView src={aboutImageSrc} imageViewStyle='imageview--full' />
            <TitleSubtitle title='Our vision for the future of animal hospitals.' subtitle="Our vision for is to become a one-stop solution for all pet health concerns. We aim to address pet issues swiftly and accurately online. Additionally, we aspire to collaborate with diagnostic laboratories for physical examinations. Our ultimate goal is to create a seamless, integrated, and comprehensive online platform for pet healthcare, ensuring your pet's wellbeing is our top priority." />
        </div>
        
    </>
  )
}

export default AboutHero