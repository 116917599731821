import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import ContactsHero from '../ContactsHero'

function Contacts() {
  return (
    <>
        <ContactsHero />
        <Footer></Footer>
    </>
  )
}

export default Contacts